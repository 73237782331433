import styled from 'styled-components';
import ButtonStyle from '../../components/button/button.style';
import PageContent from '../../components/pageContent/pageContent';
import HexBackGroundImg from '../../imgs/hexSobrePageV2.svg';
import HexagonoHomePage from '../../imgs/hexHomePage.svg';
import { theme } from '../../themes/theme';
import Button from '../../components/button/button';

const { secondaryColor, tertiaryColor, quaternaryColor, fifthColor, tabletDimension, cellDimension } = theme;

export const HomePage = styled.div`
    width: 100%;
    background-image: url(${HexagonoHomePage});
    background-repeat: no-repeat;
    background-size: 310px 530px;
    background-position: right 690px;

    @media (max-width: 1005px){
        background-position: right 790px;
    }
    @media (max-width: ${tabletDimension}){
        background-position: right 680px;
    }
    @media(max-width: ${cellDimension}){
        max-height: 450px;
        background-position: right 380px;
        background-size: 144px 240px;
        padding-bottom: 90px;
    }
`

export const DivHomePage = styled.div`
    max-width: 1980px;
    margin: 0 auto;
    padding-bottom: 21rem;
`
export const HomePageContent = styled(PageContent)`
    padding-left: 10.625rem;
    padding-right: 10.625rem;
    padding-top: 16.875rem;

    h2{
        margin: 0;
        max-width: 43.75rem;
        font-size: 3.125rem;
        font-weight: 500;
        line-height: 66px;
        letter-spacing: 1px; 
        color: ${tertiaryColor};
        margin-bottom: 1.25rem;
    }
    p{
        padding-top: 0.625rem;
        max-width: 39.375rem;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 30px;
        text-align: justify;
        color: ${quaternaryColor};
    }
    @media (max-width: 1005px){
        p{
            padding-top: 6.25rem;
        }
    }

    @media (max-width: ${tabletDimension}){
        padding-left: 1rem;
        padding-right: 1rem;

        h2{
            font-weight: 600;
            font-size: 2.188rem;
            line-height: 50px;
            letter-spacing: 0;
            margin: 0;
        }
        p{
            padding-top: 0.625rem;
        }
    }

    @media (max-width: ${cellDimension}){
        padding-top: 8.75rem;
        padding-bottom: 3.125rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        max-width: 300px;

        h2{
            font-size: 1.375rem;
            font-weight: 600;
            line-height: 30px;
            font-size: 1.563rem;
            letter-spacing: 1px;
        }
        p{
            padding-top: 15px;
            font-size: 14px;
            line-height: 21.5px;
            text-align: start;
        }
    }
`

export const DivButton = styled.div`
    display: flex;
    column-gap: 30px;
    padding-left: 10.625rem;
    padding-right: 10.625rem;
    padding-top: 6.875rem;

    @media (max-width: ${tabletDimension}){
        row-gap: 10px;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 5rem;
    }
    @media (max-width: ${cellDimension}){
        row-gap: 10px;
        padding-left: 2.5rem;
        padding-right: 1rem;
        padding-top: 0;
        column-gap: 10px;
    }

`

export const ButtonPage = styled(Button)`
    height: 40px;
    width: 189px;

    @media (max-width: ${cellDimension}){
        width: 89px;
        font-size: 0.625rem;
    }
`

export const ButtonPageWhite = styled(ButtonStyle)`
    height: 40px;
    width: 189px;
    background-color: ${fifthColor};
    border-color: ${fifthColor};
    color: ${tertiaryColor};

    @media (max-width: ${cellDimension}){
        width: 89px;
        font-size: 10px;
    }
`

export const AboutPage = styled.div`
    width: 100%;
    background-color: ${secondaryColor};
    background-image: url(${HexBackGroundImg});
    background-repeat: no-repeat;
    background-position: -10px -240px;
    background-size: 350px 650px;

    @media (max-width: ${tabletDimension}){
        background-size: 450px 450px ;
    }
    
    @media (max-width: ${cellDimension}){
        background-position: 0px -85px;
        background-size: 250px 250px;
    }
`

export const LogoHive = styled.img`
    width: 345px;
    height: 119px;
    margin: 0 auto;
    flex: 1;
    @media (max-width: 1280px){
        display: none;
    }

`

export const AboutContent = styled(PageContent)`
    padding-left: 10.625rem;
    padding-top: 160px;
    word-wrap: break-word;
    text-align: justify;
    max-width: 33.75rem;

    h2{
        font-size: 2.25rem;
        font-weight: 500;
        line-height: 48px;
    }
    
    p{
        font-size: 1rem;
        font-weight: 400;
        line-height: 26px;
    }
    @media (max-width: ${tabletDimension}){
        max-width: 100%;
        line-height: 2px;
        font-size: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    @media (max-width: ${cellDimension}){
        text-align: start;
        p{
            font-size: 0.875rem;
        }
    }
`

export const DivSubContent = styled.div`
    display: flex;
    column-gap: 30px;
    padding-left: 10.625rem;
    padding-bottom: 162px;
    padding-top: 140px;

    @media (max-width: 1280px){
        padding-top: 8rem;
    }

    @media (max-width: ${tabletDimension}){
        padding-left: 1rem;
        padding-right: 1rem;
        flex-direction: column;
        gap: 40px;
        padding-top: 40px;
    }

    @media (max-width: ${cellDimension}){
        padding-top: 3.125rem;
        padding-bottom: 8.125rem;
    }
`

export const SecurityContent = styled(PageContent)`
    text-align: justify;
    word-wrap: break-word;
    width: 15.938rem;
   
    h2 {
        font-size: 1rem;
        font-weight: 500;
        line-height: 26px;
    }
    p {
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 18px;
    }

    @media (max-width: ${tabletDimension}){
        width: 100%;
    }

    @media (max-width: ${cellDimension}){
        text-align: start;
        p{ 
            font-size: 0.625rem;
        }
    }
    
`

export const CreationContent = styled(PageContent)`
    text-align: justify;
    word-wrap: break-word;
    width: 15.938rem;

    h2 {
        font-size: 1rem;
        font-weight: 500;
        line-height: 26px;
    }
    p {
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 18px;
    }

    @media (max-width: ${tabletDimension}){
        width: 100%;
    }

    @media (max-width: ${cellDimension}){
        text-align: start;
        p{ 
            font-size: 0.625rem;
        }
    }
`

export const DivAboutPage = styled.div`
    max-width: 1980px;
    margin: 0 auto;
    display: flex;
    align-items: center;
`

export const DivAboutContentInner = styled.div`
    flex: 1;
`