import ButtonComponent from "./button.style";
interface Props{
    type?: "button" | "submit";
    className?: string;
    children?: React.ReactNode;
    referenceId?: string;
}

export default function Button({type, children, referenceId, className}: Props){
    const handleButtonClick = () => {
        if (referenceId) {
            const contactId = document.getElementById(referenceId);
            if (contactId)
                contactId.scrollIntoView({ behavior: 'smooth'});
        }
      };

    return(
        <ButtonComponent className={className} type={type} onClick={handleButtonClick}>
            {children}
        </ButtonComponent>
    )
}