import { ParagraphFooter, FooterContainer, DivImagelogoHiveFuse, FooterImagelogoHiveFuse, DivParagrapfFooter, SocialIcon, DivSocialIcon, LogoFooterMobile } from "./footer.style";
import LogoDesktop from "../../imgs/logoFooterDesktop.svg";
import LogoMobile  from "../../imgs/logoFooterMobile.svg";
import IconInstagram from "../../imgs/iconeInstagram.svg";
import IconLinkedin from "../../imgs/iconeLinkedin.svg";

export default function Footer () {
    return(
        <FooterContainer>
            <DivParagrapfFooter>
                <ParagraphFooter>&copy; 2021 Hivefuse</ParagraphFooter>
            </DivParagrapfFooter>

            <DivImagelogoHiveFuse>
                <FooterImagelogoHiveFuse src= {LogoDesktop} alt="logo HiveFuse no rodape" />
                <LogoFooterMobile src= {LogoMobile} alt="logo HiveFuse no rodape"/>   
            </DivImagelogoHiveFuse>

            <DivSocialIcon>
                <SocialIcon href="https://www.instagram.com/hivefuse/" target="_blank" >
                    <img src={IconInstagram}/>
                </SocialIcon>

                <SocialIcon href="https://www.linkedin.com/company/hive-fuse/" target="_blank">
                    <img src={IconLinkedin}/>
                </SocialIcon>   
            </DivSocialIcon>
        </FooterContainer>
    );
};