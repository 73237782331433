import Header from '../../components/header/header';
import LogoHiveAboutPage from '../../imgs/LogoHiveAboutPage.svg';
import PageContact from './components/pageContact/pageContact';
import PageSummary from './components/pageSummary/pageSummary';
import {
    HomePageContent,
    AboutPage,
    LogoHive,
    ButtonPage,
    ButtonPageWhite,
    AboutContent,
    SecurityContent,
    CreationContent,
    DivButton,
    DivSubContent,
    HomePage,
    DivHomePage,
    DivAboutPage,
    DivAboutContentInner
} from './home.style';

export default function Home() {
  return (
    <>
      <HomePage id='home'>
        <DivHomePage>
          <Header/>
          <HomePageContent>
            <h2>Somos uma empresa <br/> de tecnologia e inovação</h2>
            <p>Seja bem-vindo a HiveFuse! Somos uma consultoria em tecnologia recifense especializada em inovação. Buscamos soluções que possam gerar impacto extraordinário nos negócios dos nossos clientes.</p>
          </HomePageContent>
          <DivButton>
            <ButtonPage referenceId='contact'>Contratar</ButtonPage>
            <a href='mailto:contato@hivefuse.com.br'>
              <ButtonPageWhite>Contato</ButtonPageWhite>
            </a>
          </DivButton>
        </DivHomePage>
      </HomePage>
        
      <AboutPage id='about'>  
        <DivAboutPage>
          <DivAboutContentInner>
            <AboutContent>
              <h2>Sobre</h2>
              <p>Estabelecida em 2021, a Hivefuse surgiu com o propósito de atender a demandas por soluções digitais no cenário tecnológico. Com um foco especializado em criação de projetos digitais e design inovador, trabalhamos com produtos exclusivos para atender aos desafios do nossos clientes.</p>  
            </AboutContent>
            <DivSubContent>
              <SecurityContent>
                <h2>Segurança</h2>
                <p>Nossa equipe é formada por profissionais experientes que já atuaram em projetos com grandes clientes.</p>
              </SecurityContent>
              <CreationContent>
                <h2>Criação</h2>
                <p>Contamos com um time de profissionais  qualificados em diversas especialidades para criar as melhores soluções.</p>
              </CreationContent>
            </DivSubContent>
          </DivAboutContentInner>
          <LogoHive src={LogoHiveAboutPage} alt ='Logo Hive'/>
        </DivAboutPage>
      </AboutPage>
      <PageSummary/>
      <PageContact/>
    </>
  );
}
