import { ParagraphPageContact, TitlePageContact, WrapperPageContacts, ImageHexagonoFooter, MailTo, ContactButton, Content } from './pageContact.style';
import Footer from "../../../../components/footer/footer";
import Hexagon from "../../../../imgs/hexagonoContacts.svg";

export default function PageContact () {
    return(
    <WrapperPageContacts id='contact'>
        <Content>
            <TitlePageContact>
                Saiba como podemos te ajudar
            </TitlePageContact>
            <ParagraphPageContact>Na Hivefuse, transformamos desafios em oportunidades de crescimento. Como seu parceiro estratégico, oferecemos soluções inovadoras e expertise para elevar seu produto no mercado digital.
            </ParagraphPageContact>
            <MailTo href="mailto:contato@hivefuse.com.br">
            <ContactButton>Contratar</ContactButton>
            </MailTo>
            <Footer/>
        </Content>
        <ImageHexagonoFooter src= {Hexagon} alt='Hexagono canto inferior da tela'/>
    </WrapperPageContacts>
    );
}