import styled from 'styled-components';
import { theme } from '../../themes/theme';

const { primaryFont, tertiaryColor, primaryColor} = theme;

export const Content = styled.div`
    color: ${tertiaryColor};
    font-family: ${primaryFont};
    
    h2{
        color: ${primaryColor}; 
        margin-bottom: 1rem;
    }
    p{
        color: ${primaryColor}; 
    }
`

