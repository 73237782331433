import { Content } from "./pageContent.styles";

interface Props{
    className?: string;
    children?: React.ReactNode; 
}

export default function PageContent({className, children}: Props){
    return(
        <Content className={className}>
            {children}
        </Content>
    )
}