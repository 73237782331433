import styled from 'styled-components';
import { theme } from '../../themes/theme';

const { tertiaryColor, primaryColor, primaryFont } = theme;

export const ButtonComponent = styled.button`
    border: 0;
    background-color: ${tertiaryColor};
    color: ${primaryColor};
    text-align: center;
    border-radius:5px;
    height: 40px;
    width: 125px;
    font-weight: 500;
    font-size: 16px;
    font-family: ${primaryFont};
    &:hover{
        opacity: 0.5;
        cursor: pointer;
    }
`

export default ButtonComponent;