import { Link } from "react-scroll";
import LogoHiveHeader from "../../imgs/LogoHiveHeader.svg";
import LogoHiveMobileHeader from "../../imgs/LogoTipoHive.svg";
import { DivHeader, DivLogo, DivMenu, HeaderStyle, LogoHive, LogoHiveMobile } from "./header.style";
import Button from "../button/button";

export default function Header() {
  return (
    <DivHeader>
      <HeaderStyle>
        <nav>
          <ul>
            <DivLogo>
              <LogoHive src={LogoHiveHeader} alt="Logo Hive"/>
              <LogoHiveMobile src={LogoHiveMobileHeader} alt="Logo Hive Mobile"/>
            </DivLogo>
            <DivMenu>
                <li><Link to="home" smooth={true} duration={500}>Home</Link></li>
                <li><Link to="about" smooth={true} duration={500}>Sobre</Link></li>
                <li><Link to="services" smooth={true} duration={500}>Serviços</Link></li>
                <li><Button type="button" referenceId="contact">Contratar</Button></li> 
            </DivMenu>
          </ul>
        </nav>
      </HeaderStyle>
    </DivHeader>
  );
}
