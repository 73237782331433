import { WrapperPageSummary, Title, Paragraph, ImageFrame12, ImageFrame11, Section, Content  } from './pageSummary.style';
import HexagonSummaryTop from "../../../../imgs/hexagonoSummarySuperior.svg";
import HexagonSummaryBotton from "../../../../imgs/hexagonoSummaryInferior.svg";

export default function PageSummary () {
  return (
    <WrapperPageSummary id='services'>
      <ImageFrame12 src={HexagonSummaryTop} alt='Hexagono canto superior direito' />
      <ImageFrame11 src= {HexagonSummaryBotton} alt='Hexagono no meio da tela do lado esquerdo'/>
      <Content>
        <Section>
          <Title>Front-end</Title>
          <Paragraph>
            Sabemos que a presença online é essencial para qualquer produto, por isso atuamos com especialistas em front-end, combinando design e funcionalidade.  Atuamos com metodologias ágeis para que, juntos, possamos construir e agregar valor a sua marca.
          </Paragraph>
          <Title>UX e UI</Title>
          <Paragraph>
            Na Hivefuse realizamos pesquisas para estratégias coerentes com a realidade de cada projeto. Nossa equipe é composta por especialistas altamente qualificados na criação de designs que combinam estética e funcionalidade, priorizando a criação de experiências centradas no usuário.
          </Paragraph>
          <Title>Segurança</Title>
          <Paragraph>
            Reconhecemos que a segurança é a base fundamental de toda plataforma online. Nossa prioridade é assegurar a integridade dos dados  contra ameaças externas seguindo as melhores práticas do setor.
          </Paragraph>
        </Section>
      </Content>
    </WrapperPageSummary>
  );
}