import styled from "styled-components";

export const WrapperPageSummary = styled.div`
    position: relative;
    max-width: 100%;
    overflow: hidden;
`;

export const Content = styled.div`
    max-width: 1980px;
    margin: 0 auto;
    background-color: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: 450px){
        padding-left: 1rem;
        padding-right: 41px;
        padding-bottom: 0;
      }
`;

export const Title = styled.h2`
    color: #091133;  
    font-size: 2.25rem; 
    font-family: Poppins; 
    font-weight: 500; 
    line-height: 3rem;
    margin-bottom: 1rem;
    margin-top: 10.625rem;

    @media (max-width: 450px){
        font-size: 1.563rem;
        margin-top: 5.563rem;
    }
`;

export const Paragraph = styled.p`
    color: #333333; 
    font-size: 1rem; 
    font-family: Poppins; 
    font-weight: 400; 
    line-height: 1.625rem; 
    word-wrap: break-word;
    text-align: left;
    margin-top: 1em;

    @media (max-width: 450px){
        text-align: left;
        font-size: 14px;
    }
`;

export const Section = styled.div`
    max-width: 662px;
    margin-bottom: 323px;
    margin-left: 10.625rem;
    margin-right: 10.625rem;

    @media (max-width: 768px){
        padding-left: 1rem;
        padding-right: 1rem;
        margin-left: 0;
        margin-right: 0;
    }

    @media (max-width: 450px){
        padding-left: 0;
        padding-right: 0;  
        margin-bottom: 161px;
    }
`;

export const ImageFrame12 = styled.img`
    width: 573px;
    height: 613px;
    position: absolute;
    top: -190px;
    right: 0px;
    z-index: 1;

    @media (max-width: 1270px){
        width: 21.563rem;
        height: 23.063rem;
    }

    @media (max-width: 450px){
        top: -140px;
        right: -110px;
        width: 233px;
        height: 235px;
    }
`;

export const ImageFrame11 = styled.img`
    position: absolute;
    bottom: -290px;
    left: 0px;
    z-index: 1;

    @media (max-width: 1270px){
        width: 350px;        
        height: 350px;
        left:-95px;
        bottom: -160px;
    }
`;