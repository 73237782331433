import styled from "styled-components";

export const FooterContainer = styled.footer`
    width: 75%;
    background-color: black;
    display: flex;
    margin-top: 15rem;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 6.25rem;

    @media (max-width: 450px){
      max-width: 341px;
      height: 85px;
      margin-left: 20px;
      margin-bottom: 20px;
      margin-right: 20px;
      margin-top: 100px;
      padding-bottom: 0;
    }
`;

export const ParagraphFooter = styled.p`
  color: #939EA4; 
  font-size: 14px; 
  font-family: Poppins; 
  font-weight: 500; 
  line-height: 24px; 
  word-wrap: break-word;
  margin-left: 0px;
`;

export const DivImagelogoHiveFuse = styled.div`
  display: inline-block;
`;

export const FooterImagelogoHiveFuse = styled.img`
  width: 100px; 
  height: 84px;

  @media (max-width: 450px){
    display: none;
  }
`;

export const DivParagrapfFooter = styled.div`
  display: inline-block;
`;

export const DivSocialIcon = styled.div`
  display: inline-block;
  z-index: 1;
  position: relative;
`;

export const SocialIcon = styled.a`
  margin: 13px;
  margin-left: 15px;
`;

export const LogoFooterMobile = styled.img`
  width: 60px; 
  height: 61px;

  @media (min-width: 451px){
    display: none;
  }
`;