import styled from "styled-components";
import Button from "../../../../components/button/button";
import { theme } from "../../../../themes/theme";

export const WrapperPageContacts = styled.div`
  background-color: black;
  max-width: 100%;
  position: relative;
`;

export const Content = styled.div`
  max-width: 1980px;
  margin: 0 auto;
  padding-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media (max-width: 450px){
    padding-top: 0;
  }
`;

export const TitlePageContact = styled.h2`
width: 100%;
text-align: center; 
color: #F2F2F2; 
font-size: 36px; 
font-family: Poppins; 
font-weight: 500; 
margin: 0;

@media (max-width: 450px){
  max-width: 263px;
  font-size: 25px;
  line-height: 30px; 
  margin-top: 75px;
  margin-bottom: 16px;
}
`;

export const ParagraphPageContact = styled.p`
max-width: 50%;
text-align: center; 
color: white; 
font-size: 16px; 
font-family: Poppins; 
font-weight: 400; 
line-height: 26px; 
white-space: pre-line;
padding-top: 3%;
margin-bottom: 150px;

@media (max-width: 450px){
  max-width: 300px;
  word-wrap: break-word;
  padding-left: 0;
  margin-bottom: 60px;
}
`;

export const ImageHexagonoFooter = styled.img`
  width: 473px;
  height: 513px;
  position: absolute;
  bottom: 0;
  right: 0;

  @media (max-width: 870px){
    width: 400px;
  }

  @media (max-width: 768px){
    width: 373px;
    height: 413px;
  }

  @media (max-width: 725px){
    width: 273px;
    height: 313px;
  }

  @media (max-width: 450px){
    display: none;
  }
`;

export const MailTo = styled.a`
  text-decoration: none;
`;

export const ContactButton = styled(Button)`
    height: 40px;
    width: 189px;
    color: ${theme.secondaryColor};
    text-align: center;
    font-family: Poppins;
    font-size: 0.875rem;
    font-weight: 500;
    background-color: ${theme.primaryColor};
`;