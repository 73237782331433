import styled from 'styled-components';
import { theme } from '../../themes/theme';

const { primaryColor, cellDimension, tabletDimension, primaryFont } = theme;

export const DivHeader = styled.div`
  width: 100%;
  position: fixed;
  z-index: 10;
  margin: 0 auto;
  left: 0;
  right: 0;
`

export const HeaderStyle = styled.header`
  background: ${primaryColor};
  font-family: ${primaryFont};
  max-width: 100%;
  font-size: 14px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.10);

  ul{
    display: flex;
    justify-content: space-between;
    align-items: center; 
    height: 98px; 
    padding: 0;
    margin: 0;
    padding-right: 185px;

    @media (max-width: ${tabletDimension}){
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;
      padding-right: 0;
  }
  @media (max-width: ${cellDimension}){
      justify-content: space-between;
  }
}     
`
export const LogoHive = styled.img`
  padding-left: 166px; 

  @media (max-width: 1150px){
    padding-left: 110px; 
  }

  @media (max-width: ${tabletDimension}){
    padding-left: 0; 
  }

  @media (max-width: ${cellDimension}){
    display: none;
  }
`
export const LogoHiveMobile = styled.img`
  display: none;
  width: 120px;
  height: 80px;
  @media (max-width: ${cellDimension}){
    display: block;
  }
`
export const MenuHam = styled.button`
  width: 49px;
  height: 46px;
  display: none;
  border: none;
  padding-right: 4rem;
  background-color: ${primaryColor};

  img{
    align-items: center;
    align-content: center;
  }

  @media (max-width: ${cellDimension}){
    display: block;
  }
`
export const DivMenu = styled.div`
    align-items: center;
    display: flex;
    column-gap: 50px;
    margin-left: 10%;

    li {
      list-style-type: none; 

      button{
      font-size: 12px;
      }
  }

  a {
      color: #828282;
      text-decoration: none;
      font-weight: 400;
      line-height: 24px;
      &:hover{
        opacity: 0.2;
        cursor: pointer;
      }
    }

    @media (max-width: ${tabletDimension}){
      margin-left: 0;
    }
    @media (max-width: ${cellDimension}){
      li{
        display: none;
      }
    }

`

export const DivLogo = styled.div`
    align-items: center;
    display: flex;
    column-gap: 50px;
`

